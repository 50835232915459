import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { ConfigContextConsumer } from '../config/ConfigContext'
import axios, { AxiosResponse } from 'axios'

const tokenCookieName = 'token'

export type TripsMenuState = {
  pending: any[]
  confirmed: any[]
  completed: any[]
  hasTrips: boolean
}

export const TripsMenuContext = React.createContext<TripsMenuState>({
  pending: [],
  confirmed: [],
  completed: [],
  hasTrips: false
})

interface TripsMenuContextProviderCoreProps {
  baseAppUrl: string
  isLoggedIn: boolean
  children: any
}

const TripsMenuContextProviderCore = ({
  baseAppUrl,
  isLoggedIn,
  children
}: TripsMenuContextProviderCoreProps) => {
  const [cookies] = useCookies()
  const token = cookies[tokenCookieName]
  // Determine auth state by stored token
  const initialTripsMenuState: TripsMenuState = {
    pending: [],
    confirmed: [],
    completed: [],
    hasTrips: false
  }
  const [tripsMenuState, updateTripsMenuState] = useState(initialTripsMenuState)

  useEffect(() => {
    // NOTE: Keeps tripstates in memory after logout
    if (isLoggedIn) {
      const visited: { [key: string]: boolean } = {}
      const now = Date.now()
      const processTripStates = (tripStates: any) => {
        const newTripMenuState: TripsMenuState = {
          pending: [],
          confirmed: [],
          completed: [],
          hasTrips: false
        }
        // reservations = {}
        tripStates.forEach((tripState: any) => {
          const tid = tripState._id.toString()
          // Not sure why we would revisit an id
          if (!visited[tid]) {
            if (tripState.confirmed) {
              visited[tid] = true
              // Upcoming confirmed trips
              if (Date.parse(tripState.startDate) > now || Date.parse(tripState.endDate) > now) {
                newTripMenuState.confirmed.push(tripState)
                newTripMenuState.hasTrips = true
              } else {
                newTripMenuState.completed.push(tripState)
                newTripMenuState.hasTrips = true
              }
            } else {
              // Upcoming trips that aren't confirmed
              if (tripState.startDate && Date.parse(tripState.startDate) > now) {
                visited[tid] = true
                newTripMenuState.pending.push(tripState)
                newTripMenuState.hasTrips = true
              }
            }
          }
        })

        // By end date ascending order
        newTripMenuState.pending.sort((t1, t2) => Date.parse(t1.endDate) - Date.parse(t2.endDate))
        // By end date descending order
        newTripMenuState.completed.sort((t1, t2) => Date.parse(t2.endDate) - Date.parse(t1.endDate))
        // By end date ascending order
        newTripMenuState.confirmed.sort((t1, t2) => Date.parse(t1.endDate) - Date.parse(t2.endDate))

        updateTripsMenuState(newTripMenuState)
      }
      const fetchTrips = async () => {
        const response: AxiosResponse<any> = await axios({
          method: 'get',
          url: `${baseAppUrl}/api/tripstates`,
          withCredentials: true,
          headers: { Authorization: `Bearer ${token}` }
        })
        const data = response.data
        processTripStates(data)
        // setTripStates(data)
      }
      fetchTrips()
    }
  }, [isLoggedIn, baseAppUrl, token]) // Empty array to only run once

  return (
    <TripsMenuContext.Provider value={{ ...tripsMenuState }}>{children}</TripsMenuContext.Provider>
  )
}

export const TripsMenuContextProvider = ({
  children,
  isLoggedIn
}: {
  children: any
  isLoggedIn: boolean
}) => {
  return (
    <ConfigContextConsumer>
      {({ baseAppUrl }) => (
        <TripsMenuContextProviderCore baseAppUrl={baseAppUrl} isLoggedIn={isLoggedIn}>
          {children}
        </TripsMenuContextProviderCore>
      )}
    </ConfigContextConsumer>
  )
}

export const TripsMenuContextConsumer = TripsMenuContext.Consumer
