/* eslint-disable react/display-name */
import React, { useMemo } from 'react'
import { Button, Dropdown, Menu } from 'antd'
import { css } from '@emotion/react'
import {
  TripsMenuContextConsumer,
  TripsMenuContextProvider,
  TripsMenuState
} from './TripsMenuContext'

const { SubMenu } = Menu

const styles = {
  desktopMenuBtn: css`
    background: transparent;
    border-color: transparent;
    color: #222222;
    border: none;

    &:hover {
      background-color: rgba(255, 255, 255, 0.3);
      border: none;
      border-radius: 2px;
    }
  `,

  dropdownMenu: css`
    .ant-dropdown-menu-item-group-title,
    .ant-menu-item-group-title {
      font-weight: 600;
      color: #121673;
    }
  `
}

const accountMenuItems = (baseAppUrl, logout) => {
  return [
    <Menu.Item style={{ padding: '8px 15px' }} key={1}>
      <a target="_blank" rel="noopener noreferrer" href={`${baseAppUrl}/profile`}>
        Profile
      </a>
    </Menu.Item>,
    <Menu.Divider key={2} />,
    <Menu.Item style={{ padding: '8px 15px' }} key={3}>
      <a rel="noopener noreferrer" onClick={logout}>
        Logout
      </a>
    </Menu.Item>
  ]
}

const accountOverlay = (baseAppUrl, logout) => () => {
  return <Menu style={{ width: 160 }}>{accountMenuItems(baseAppUrl, logout)}</Menu>
}

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
const getDateRange = (start: string, end: string) => {
  const startDate = new Date(start)
  const endDate = new Date(end)
  return `(${months[startDate.getMonth() - 1]} ${startDate.getDate()}-${
    months[endDate.getMonth() - 1]
  } ${endDate.getDate()})`
}

const reservationsGroup = (title: string, trips: any, baseAppUrl: string) => {
  return trips.length ? (
    <Menu.ItemGroup title={title} key={title}>
      {trips.map((trip: any, index: number) => (
        <Menu.Item key={index}>
          <a rel="noopener noreferrer" href={`${baseAppUrl}/interview/${trip._id}`}>
            {trip.name} {getDateRange(trip.startDate, trip.endDate)}
          </a>
        </Menu.Item>
      ))}
    </Menu.ItemGroup>
  ) : null
}

const tripsMenuItems = (context, baseAppUrl) => {
  return [
    reservationsGroup('Pending', context.pending, baseAppUrl),
    reservationsGroup('Confirmed', context.confirmed, baseAppUrl),
    reservationsGroup('Completed', context.completed, baseAppUrl)
  ]
}

const reservationsOverlay = (context, baseAppUrl) => (
  // Passing to overlay renders too often
  <Menu css={styles.dropdownMenu} style={{ minWidth: 160 }}>
    {tripsMenuItems(context, baseAppUrl)}
  </Menu>
)

interface NavProps {
  baseAppUrl: string
  user: any
  logout: () => {}
  isLoggedIn: boolean
}

interface MenuProps {
  context: TripsMenuState
  baseAppUrl: string
  user: any
  logout: () => {}
}

const DesktopDropdowns = ({ context, baseAppUrl, user, logout }: MenuProps) =>
  useMemo(() => {
    return (
      <>
        {context.hasTrips ? (
          <Dropdown overlay={reservationsOverlay(context, baseAppUrl)} placement="bottomRight">
            <Button css={styles.desktopMenuBtn}>Your Reservations</Button>
          </Dropdown>
        ) : null}
        <Dropdown overlay={accountOverlay(baseAppUrl, logout)} placement="bottomRight">
          <Button css={styles.desktopMenuBtn}>{user.firstName}</Button>
        </Dropdown>
      </>
    )
  }, [context, baseAppUrl, user, logout])

export const DesktopMenu = ({ baseAppUrl, user, logout, isLoggedIn }: NavProps) => {
  return (
    <TripsMenuContextProvider isLoggedIn={isLoggedIn}>
      <TripsMenuContextConsumer>
        {context => (
          <DesktopDropdowns context={context} user={user} logout={logout} baseAppUrl={baseAppUrl} />
        )}
      </TripsMenuContextConsumer>
    </TripsMenuContextProvider>
  )
}

const SiderMenus = ({ context, baseAppUrl, user, logout }: MenuProps) =>
  useMemo(() => {
    return (
      <Menu css={styles.dropdownMenu} style={{ width: 256 }} mode="inline">
        {context.hasTrips ? (
          <SubMenu key="sub1" title="Your Reservations">
            {tripsMenuItems(context, baseAppUrl)}
          </SubMenu>
        ) : null}
        <SubMenu title={user.firstName}>{accountMenuItems(baseAppUrl, logout)}</SubMenu>
      </Menu>
    )
  }, [context, baseAppUrl, user, logout])

export const MobileMenu = ({ baseAppUrl, user, logout, isLoggedIn }: NavProps) => {
  return (
    <TripsMenuContextProvider isLoggedIn={isLoggedIn}>
      <TripsMenuContextConsumer>
        {(context: TripsMenuState) => (
          <SiderMenus context={context} user={user} logout={logout} baseAppUrl={baseAppUrl} />
        )}
      </TripsMenuContextConsumer>
    </TripsMenuContextProvider>
  )
}
