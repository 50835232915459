import React from 'react';
import { css } from '@emotion/react';

const styles = {
  overlay: css`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  `,
  modal: css`
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 100%;
    max-height:100%;
    min-height: 100%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: relative;
    
  `,
  closeButton: css`
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
  `
};

type DestinationsModalPropsType = {
  isOpen: boolean;
  onClose: any;
  children: any;
}

const DestinationsModal = ({ isOpen, onClose, children } : DestinationsModalPropsType) => {
  if (!isOpen) return null;

  return (
    <div css={styles.overlay} onClick={onClose}>
      <div css={styles.modal} onClick={(e) => e.stopPropagation()}>
        <button css={styles.closeButton} onClick={onClose}>
          &times;
        </button>
        {children}
      </div>
    </div>
  );
};

export default DestinationsModal;