import React, { memo } from 'react'
import { Typography, Row, Col } from 'antd'
import { css } from '@emotion/react'
const { Title, Paragraph } = Typography

const styles = {
  slat: css`
    width: 100%;
    background-size: cover;
    background-position: 50% 30%;
    position: relative;
    padding: 50px;
    max-width: 980px;
    margin: auto;

    @media (max-width: 768px) {
      padding: 30px 10px;
    }
  `,
  title: css`
    text-align: center;
  `,
  learn: css`
    text-align: center;
    font-size: 20px !important;
    &:hover {
      text-decoration: underline !important;
    }
  `,
  question: css`
    font-size: 14px !important;
    font-weight: 500;
    font-family: Tahoma, Geneva, sans-serif;
  `,
  col: css`
    padding-left: 10px;
    padding-right: 10px;
  `,
  container: css`
    padding-left: 10px;
    padding-right: 10px;
  `,
  questions: css`
    padding: 10px 0;
  `
}

const Faq = () => {
  return (
    <div css={styles.slat}>
      <div css={styles.container}>
        <Title level={2} css={styles.title}>
          FAQ
        </Title>
        <Title level={3} css={styles.learn}>
          <a href="https://www.stayfari.com/blog">Learn more from our blog</a>
        </Title>
        <Row justify="center" align="top" css={styles.questions}>
          <Col md={12} xs={24} css={styles.col}>
            <Title level={4} css={styles.question}>
              Where can I hire a car and driver?
            </Title>
            <Paragraph>
              We offer chauffeured vehicles and related services throughout India, Sri Lanka, Nepal
              and Mauritius.
            </Paragraph>
            <Title level={4} css={styles.question}>
              For how long can I hire a car and driver?
            </Title>
            <Paragraph>
              We accommodate everything from airport transfers to multi-week rentals.
            </Paragraph>
            <Title level={4} css={styles.question}>
              For how many hours is a driver on duty?
            </Title>
            <Paragraph>
              Up to eight hours when driving locally and 12 hours outstation. Additional hours will
              typically incur fees.
            </Paragraph>
          </Col>
          <Col md={12} xs={24} css={styles.col}>
            <Title level={4} css={styles.question}>
              Are there any mileage limits?
            </Title>
            <Paragraph>
              Within the cities in your itinerary, there are no mileage limits. Itineraries are not
              required in Mauritius or Goa.
            </Paragraph>
            <Title level={4} css={styles.question}>
              Are any common charges excluded?
            </Title>
            <Paragraph>
              No, our quotes include parking, tolls and driver expenses. There are no nighttime or
              waiting charges.
            </Paragraph>
            <Title level={4} css={styles.question}>
              What if I need to cancel my reservation?
            </Title>
            <Paragraph>
              You will be refunded in full so long as you cancel at least 24 hours before your
              pickup time.
            </Paragraph>
          </Col>
        </Row>
      </div>
    </div>
  )
}
export default memo(Faq)
