import React from 'react'
import GetStartedChat from './GetStartedChat'
import { Typography } from 'antd'
import { css } from '@emotion/react'
const { Title, Text } = Typography

const styles = {
  title: css`
    font-family: 'Trebuchet MS', sans-serif;
    margin-bottom: 0 !important;
    color: #006 !important;
    font-size: 42px !important;
    padding-left: 20px;
    padding-right: 20px;

    @media (max-width: 768px) {
      font-size: 30px !important;
    }
  `,
  subtitle: css`
    font-family: 'Trebuchet MS', sans-serif;
    margin-top: 0 !important;
    font-size: 22px !important;
    color: #003 !important;

    @media (max-width: 768px) {
      font-size: 16px !important;
    }
  `
}
const container = css`
  width: 100%;
  height: 510px;
  background-size: cover;
  background-position: 50% 30%;
  position: relative;
  text-align: center;
  padding-top: 50px;
`

const tpLogo = css`
  height: 26px;
  display: inline-block;
  vertical-align: baseline;
  margin-left: 5px;
  margin-bottom: -5px;
`

// TODO: Copied form stickyfooter
const cta = css`
  background-color: #ec781d; //orange
  font-size: 18px;
  font-weight: 500;
  padding: 3px;
  width: 250px;
  border: none;

  &:hover {
    background-color: #ff9933; //light orange
  }

  &:active,
  &:focus {
    background-color: #ec781d; //orange
  }

  // margin-top: 40vh;
  position: absolute;
  bottom: 50px;
  margin-left: calc(50% - 125px);
`

interface HeroProps {
  readonly title: string
  readonly subtitle: string
  readonly img: string
  readonly main: boolean
}

const Hero = ({ title, subtitle, img, main }: HeroProps) => {
  return (
    <div>
      <div css={container} style={{ backgroundImage: `url(${img})` }}>
        <Title level={1} css={styles.title}>
          {main ? 'Hire a car and driver' : title}
        </Title>
        <Title level={2} css={styles.subtitle}>
          {subtitle}
        </Title>
        <div>
          <svg
            viewBox="0 0 128 24"
            width="88"
            height="16"
            fill="#00aa6c"
            aria-labelledby=":lithium-rhu:"
            data-automation="reviewBubbles"
          >
            <title id=":lithium-rhu:">4.5 of 5 bubbles</title>
            <path
              d="M 12 0C5.388 0 0 5.388 0 12s5.388 12 12 12 12-5.38 12-12c0-6.612-5.38-12-12-12z"
              transform=""
            ></path>
            <path
              d="M 12 0C5.388 0 0 5.388 0 12s5.388 12 12 12 12-5.38 12-12c0-6.612-5.38-12-12-12z"
              transform="translate(26 0)"
            ></path>
            <path
              d="M 12 0C5.388 0 0 5.388 0 12s5.388 12 12 12 12-5.38 12-12c0-6.612-5.38-12-12-12z"
              transform="translate(52 0)"
            ></path>
            <path
              d="M 12 0C5.388 0 0 5.388 0 12s5.388 12 12 12 12-5.38 12-12c0-6.612-5.38-12-12-12z"
              transform="translate(78 0)"
            ></path>
            <path
              d="M 12 0C5.389 0 0 5.389 0 12c0 6.62 5.389 12 12 12 6.62 0 12-5.379 12-12S18.621 0 12 0zm0 2a9.984 9.984 0 0110 10 9.976 9.976 0 01-10 10z"
              transform="translate(104 0)"
            ></path>
          </svg>
          <img
            css={css`
              font-weight: 700;
              font-size: 16px;
            `}
            src="https://ik.imagekit.io/stayfari/general/Tripadvisor_Logo_circle-green_horizontal-lockup_registered_RGB_oTG1mECsp.png?updatedAt=1711568140890"
            decoding="async"
            loading="lazy"
            style={{ height: '26px', marginLeft: '5px', marginBottom: '8px' }}
            alt="TripAdvisor Reviews"
          />
        </div>
        <GetStartedChat />
      </div>
    </div>
  )
}
export default Hero
