// import React from 'react';
// import { storeToken } from './Token';
import BSON from 'bson'
import axios, { AxiosResponse } from 'axios'

export interface Credential {
  email: string
  password: string
  token?: string
}

export interface User extends Credential {
  firstName?: string
  lastName?: string
  _id?: BSON.Long
  isNewTrip?: boolean
  tripId?: BSON.Long
  tripOwnerId?: BSON.Long
}

// let currentToken: string;

// Nice utility for SSR
// export const isBrowser = () => typeof window !== "undefined"

// const setUser = (user: User) => {
// }

// const Auth = () => {

// return {
// export const setUser = (user: User, token: string) => {
//     //Set promise to undefined so because user is being set by external method.
//     setUser(user);
//     // storeToken(token);
//     currentToken = token;
//     // $analytics.setUsername(user._id); // Inform GA of user
//     //Set logging context for tracing
//     // $log.setUser(user._id);
// }
// }
// }

// export const logout = async () => {
//     // $log.info("(logout) ", currentUser ? currentUser._id : 'no user');
//     Token.clearToken();
//     // $log.unsetUser();
//     currentToken = null;
//     //set user undefined;

//     setUser(undefined);
//     // CreditNotif.reset();
// }

export const authenticateGenerator = (baseAppUrl: string | undefined) => async (
  email: string,
  password: string,
  tripId?: BSON.Long,
  isNewTrip?: boolean,
  tripOwnerId?: BSON.Long
) => {
  const user: User = { email: email, password: password }

  if (tripId) user.tripId = tripId
  if (isNewTrip) user.isNewTrip = isNewTrip
  if (tripOwnerId) user.tripOwnerId = tripOwnerId

  // try {
  // console.log('baseAppUrl: ', baseAppUrl)
  const response: AxiosResponse<any> = await axios.post(`${baseAppUrl}/auth/local`, user)

  // console.log('data :', response.data)
  const retrievedUser = response.data.user

  // Discount is track seperately from user, fold in to user for client
  if (response.data.applyPhoneDiscount) retrievedUser.applyPhoneDiscount = true
  // setUser(result.data.user, result.data.token);
  return Promise.resolve({ user: retrievedUser, token: response.data.token })
  // }
  // catch (err) {
  //     // $log.info("logging out post auth rejection")
  //     //TODO SC: Restore
  //     // logout();
  //     //Must return reject so that caller can handle UI treatment.
  //     return Promise.reject(err);
  // }
}

export const getAuthenticatedUserGenerator = (baseAppUrl: string | undefined) => async (
  token: string
) => {
  // try {
  // Uses token in cookie to identify user and return
  // let response = await axios.get('http://localhost:9001/api/users/me')
  const response = await axios({
    method: 'get',
    url: `${baseAppUrl}/api/users/me`,
    withCredentials: true,
    headers: { Authorization: `Bearer ${token}` }
  })

  // TODO
  // Set analytics user name
  // Set logging context for stuctured logging
  // Log
  // console.log('User returned: ', response.data)

  return Promise.resolve(response.data)
  // }
  // catch (err) {
  //     //TODO Log
  //     console.log('error: ', err);
  //     return Promise.reject(err);
  // }
}
