import React from 'react'
import { css } from '@emotion/react'
const logo = css`
  position: relative;
  top: 0px;
  left: 0px;
  width: 160px;
  height: 48px;
  opacity: 1;
  margin: 0;
`

export default function Logo() {
  return (
    <img
      alt="Stayfari"
      src="https://ik.imagekit.io/stayfari/general/tr:w-160,h-48,c-maintain_ratio/FL_Norwester_Elbaris_Hybrid-01_LlKpdcqksp.png?ik-sdk-version=javascript-1.4.3&updatedAt=1666155398553"
      srcSet="https://ik.imagekit.io/stayfari/general/tr:w-160,h-48,c-maintain_ratio/FL_Norwester_Elbaris_Hybrid-01_LlKpdcqksp.png?ik-sdk-version=javascript-1.4.3&updatedAt=1666155398553 150w,https://ik.imagekit.io/stayfari/general/tr:w-160,h-48,c-maintain_ratio/FL_Norwester_Elbaris_Hybrid-01_LlKpdcqksp.png?ik-sdk-version=javascript-1.4.3&updatedAt=1666155398553 160w"
      css={logo}
    />
  )
}
