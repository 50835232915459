import { useEffect, useState } from 'react'
import lunr from 'lunr'
import { Destination } from './DestinationSearch'
import { useFetchCached } from './useFetchCached'

export interface DestinationsState {
  idx?: lunr.Index
  store?: { [key: string]: Destination }
  codeMap?: { [key: string]: Destination }
  topDestinations?: Destination[]
  default?: Destination
}

const useDestinations = (): DestinationsState => {
  // const [status, setStatus] = useState<string>('idle')
  const [destinationsState, setDestinationsState] = useState<DestinationsState>({})
  const destinationsFetch = useFetchCached('dests.json')

  useEffect(() => {
    if (destinationsFetch.status === 'fetched') {
      const dests = destinationsFetch.data
      const store: { [key: string]: Destination } = {}
      const codeMap: { [key: string]: Destination } = {}
      const topDestinations: Destination[] = []
      const idx = lunr(function() {
        this.ref('id')
        this.field('label')
        this.field('name')
        this.field('suffix')

        dests.forEach(function(dest: any) {
          dest.label = dest.name + dest.suffix
          // TODO: project doc to keep index lighter
          store[dest.id] = dest
          if (dest.code) {
            codeMap[dest.code] = dest
          }

          if (dest.priority)
            topDestinations.push({
              label: dest.label,
              value: dest.id,
              name: dest.name,
              suffix: dest.suffix
              // TODO why not storing id and code? make sure top dests works
            })
          this.add(dest)
        }, this)

        // Sort alphabetically by label in place
        topDestinations.sort((a, b) => {
          return a.label.localeCompare(b.label)
        })
      })

      setDestinationsState({ idx, store, codeMap, topDestinations })
    }
  }, [destinationsFetch.status, destinationsFetch.data])

  return destinationsState
}

export default useDestinations
