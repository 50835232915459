import React from 'react'
import { Affix, Typography } from 'antd'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { css } from '@emotion/react'
import GetStartedButton from './GetStartedButton'

const { Text } = Typography

const footer = css`
  width: 100%;
  height: 50px;
  z-index: 3;
  background-color: #fff;
  box-shadow: 0px -2px 7px rgba(130, 130, 130, 0.75);
  padding: 8px 5px 5px 6px;
  display: flex;
  justify-content: center;

  .ant-anchor-ink::before {
    width: 0;
  }

  .ant-anchor-link {
    padding: 8px;
    font-size: 16px;
    font-weight: 700;

    &:hover {
      text-decoration: underline;
    }
  }
`

const prompt = css`
  font-size: 16px;
  font-weight: 700;
  padding: 4px 15px;
  color: #006;

  @media (max-width: 768px) {
    font-size: 16px;
  }
  @media (max-width: 480px) {
    font-size: 14px;
    padding: 4px 8px;
  }
`

const cta = css`
  background-color: #ec781d; //orange
  font-size: 18px;
  font-weight: 500;
  padding: 3px;
  width: 250px;
  border: none;

  &:hover {
    background-color: #ff9933; //light orange
  }

  &:active,
  &:focus {
    background-color: #ec781d; //orange
  }

  @media (max-width: 768px) {
    font-size: 16px;
    width: 150px;
  }

  @media (max-width: 480px) {
    width: 120px;
  }
`
const learnAdvisory = css`
  font-size: 16px;
  font-weight: 700;
  margin-top: 6px;
  color: black;
  &:hover {
    text-decoration: underline;
  }
  @media (max-width: 480px) {
    font-size: 14px;
  }
`

const StickyFooter = ({ inView, pathname }) => {
  return (
    <>
      {inView ? (
        <Affix offsetBottom={0}>
          <div css={footer}>
            <Text css={prompt}>Hire a car and driver</Text>
            <GetStartedButton css={cta} />
          </div>
        </Affix>
      ) : null}
    </>
  )
}

export default StickyFooter
