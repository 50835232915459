import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { User, getAuthenticatedUserGenerator, authenticateGenerator } from './Auth'
import { ConfigContextConsumer } from '../config/ConfigContext'
import { AnyARecord } from 'dns'

const tokenCookieName = 'token'

type AuthState = {
  user: User
  isLoggedIn: boolean
  pending: boolean
  login: (email: string, password: string) => Promise<any>
  logout: () => void
}

export const AuthContext = React.createContext<Partial<AuthState>>({
  isLoggedIn: false,
  pending: true
})

type AuthContextProviderProps = {
  children: any
  authenticate: any
  getAuthenticatedUser: any
}

const AuthContextProviderCore = ({
  children,
  authenticate,
  getAuthenticatedUser
}: AuthContextProviderProps) => {
  // Determine auth state by stored token
  const [cookies, setCookie, removeCookie] = useCookies()
  const token = cookies[tokenCookieName]
  const initialAuthState: Partial<AuthState> = { pending: true }
  const [authState, updateAuthState] = useState(initialAuthState)

  useEffect(() => {
    // TODO: Restore when Nav works: Don't fetch user yet
    const fetchUser = async () => {
      const newAuthState: Partial<AuthState> = {}
      // we know token at load time so can set isLoggedIn on first render
      if (token) {
        try {
          const user = await getAuthenticatedUser(token)
          newAuthState.user = user
          newAuthState.isLoggedIn = true
          // setCookie(tokenCookieName, token)
          // console.log('here')
        } catch (err) {
          // TODO Log?
          console.log('err: ', err)
          // logout();
        }
      }

      updateAuthState({ ...newAuthState, pending: false })
    }
    fetchUser()
  }, []) // empty dependency array makes this only run once onload //https://www.robinwieruch.de/react-hooks-fetch-data

  const logout = (): void => {
    removeCookie(tokenCookieName)
    // TODO log

    // TODO Notify server of invalidated token?
    updateAuthState({ user: undefined, isLoggedIn: false })
  }

  const login = async (email: string, password: string): Promise<any> => {
    try {
      const userAndToken = await authenticate(email, password)
      updateAuthState({ user: userAndToken.user, isLoggedIn: true })
      setCookie(tokenCookieName, userAndToken.token)
      return userAndToken.user
    } catch (err) {
      // TODO: Log?
      logout()
      return err
    }
  }

  return (
    <AuthContext.Provider value={{ ...authState, logout, login }}>
      {children}
      {/* <AuthContext.Consumer>
                {props => (
                    <div>
                        <h1>{props.user}</h1>
                        <span>{props.isLoggedIn}</span>
                    </div>
                )}
            </AuthContext.Consumer> */}
    </AuthContext.Provider>
  )
}

export const AuthContextProvider = ({ children }) => {
  return (
    <ConfigContextConsumer>
      {({ baseAppUrl }) => (
        <AuthContextProviderCore
          authenticate={authenticateGenerator(baseAppUrl)}
          getAuthenticatedUser={getAuthenticatedUserGenerator(baseAppUrl)}
        >
          {children}
        </AuthContextProviderCore>
      )}
    </ConfigContextConsumer>
  )
}
export const AuthContextConsumer = AuthContext.Consumer
