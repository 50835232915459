/* eslint-disable react/display-name */
import React, { SyntheticEvent, useState } from 'react'
import { Button, Drawer } from 'antd'
import { MenuOutlined } from '@ant-design/icons'
import Logo from '../media/Logo'
import { css } from '@emotion/react'
import { AuthContextConsumer } from '../auth/AuthContext'
import { DesktopMenu, MobileMenu } from './NavMenu'

const styles = {
  mobileMenuBtn: css`
    background: transparent;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    color: black;
    font-size: 35px;
    border: none; //Wipe out default styling
    padding: 4px 8px;
    height: 60px;
    margin-top: -10px; //Hack becasuse couldn't find source of extra space top of container

    @media (min-width: 768px) {
      display: none;
    }

    .anticon {
    }

    & svg {
      height: 0.7em;
      transform: scale(1.3, 0.9);
      fill: #006;
    }
  `,
  desktopMenu: css`
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    padding: 10px;
    height: 60px;
    @media (max-width: 768px) {
      display: none;
    }
  `,
  mobileMenu: css``
}
const Nav = ({ isLoggedIn, context, baseAppUrl }) => {
  const [open, setOpen] = useState(false)

  const handleDrawerOpen = (event: SyntheticEvent) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }
    setOpen(true)
  }
  const handleDrawerClose = (event: SyntheticEvent) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }
    setOpen(false)
  }

  const LoginLink = ({ context, baseAppUrl }) => (
    <div>
      <Button
        type="link"
        onClick={() => {
          window.location = `${baseAppUrl}/login`
        }}
      >
        Log In
      </Button>
    </div>
  )

  return context.pending ? null : (
    <>
      <div css={styles.mobileMenu}>
        <Button css={styles.mobileMenuBtn} type="link" onClick={handleDrawerOpen}>
          <MenuOutlined />
        </Button>
        <Drawer
          title={<Logo />}
          placement="left"
          closable={false}
          onClose={handleDrawerClose}
          open={open}
          width="auto"
        >
          {isLoggedIn ? (
            <MobileMenu
              baseAppUrl={baseAppUrl}
              user={context.user}
              logout={context.logout}
              isLoggedIn={context.isLoggedIn}
            />
          ) : (
            <LoginLink context={context} baseAppUrl={baseAppUrl} />
          )}
        </Drawer>
      </div>
      <div css={styles.desktopMenu}>
        {isLoggedIn ? (
          <DesktopMenu
            baseAppUrl={baseAppUrl}
            user={context.user}
            logout={context.logout}
            isLoggedIn={context.isLoggedIn}
          />
        ) : (
          <LoginLink context={context} baseAppUrl={baseAppUrl} />
        )}
      </div>
    </>
  )
}

// TODO SC: Remove url pull?!?
export default ({ baseAppUrl }: { baseAppUrl: string }) => {
  return (
    <AuthContextConsumer>
      {context => <Nav isLoggedIn={context.isLoggedIn} context={context} baseAppUrl={baseAppUrl} />}
    </AuthContextConsumer>
  )
}
