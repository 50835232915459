import React, { useRef, useState, useEffect } from 'react'
// import DogMp4 from "../assets/dog.mp4"
// import Captions from "file-loader!../assets/captions.vtt"
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { PlayCircleOutlined } from '@ant-design/icons'
import { Spin } from 'antd'

const VideoBox = styled.div`
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  position: relative;
  cursor: pointer;
  border-radius: 5px;

  img {
    margin: 0;
  }
  box-shadow: 12px 0 15px -4px rgba(255, 255, 255, 0.8), -12px 0 8px -4px rgba(255, 255, 255, 0.8);
`

const placeholder = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  border-radius: 5px;
`

const veil = css`
  transition: opacity 0.5s ease-out;
  background-color: rgba(255, 255, 255, 0.35);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  border-radius: 5px;
`

const playButton = css`
  font-size: 64px;
  color: #000066;
  margin-top: calc(25% - 24px);
`

const spinner = css`
  margin-top: calc(30% - 24px);
`

const video = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  border-radius: 5px;
`
export default function Video() {
  const videoRef = useRef(null)
  const ref = useRef()
  const [playing, setPlaying] = useState(false)
  const [firstPlay, setFirstPlay] = useState(true)
  const [isVideoLoaded, setIsVideoLoaded] = React.useState(false)
  const [isImgLoaded, setIsImgLoaded] = React.useState(false)
  // const [ref, loaded, onLoad] = useImageLoaded()
  const onPlayClick = () => {
    if (isVideoLoaded && videoRef.current) {
      if (playing) {
        videoRef.current.pause()
        setPlaying(false)
        ga('send', 'event', 'video', 'stop', 'covid-advisory')
      } else {
        videoRef.current.play()
        setPlaying(true)
        ga('send', 'event', 'video', 'play', 'covid-advisory')
        if (firstPlay) {
          setFirstPlay(false)
        }
      }
    }
  }
  const onVideoLoadedData = () => {
    setIsVideoLoaded(true)
  }

  const onImgLoad = () => {
    setIsImgLoaded(true)
  }

  useEffect(() => {
    if (ref.current && ref.current.complete) {
      onImgLoad()
    }
    setTimeout(onVideoLoadedData, 2000)
  }, [])

  // const onImgLoad = () => {
  //   setIsImgLoaded(true)
  // }

  return (
    <VideoBox>
      <video
        onClick={onPlayClick}
        onCanPlay={onVideoLoadedData}
        ref={videoRef}
        style={{ opacity: firstPlay ? '0' : '1' }}
        controls={false}
        css={video}
        preload="none"
      >
        <source
          src="https://ik.imagekit.io/stayfari/homepage/hiw/Stayfari_Testimonials_Trailer_Compressed_KopVZqmLN.mp4?updatedAt=1688690574752"
          type="video/mp4"
        />
      </video>
      <img
        ref={ref}
        // onLoad={onLoad}
        onClick={onPlayClick}
        onLoad={onImgLoad}
        css={placeholder}
        style={{ opacity: firstPlay && isImgLoaded ? '1' : '0' }}
        src="https://ik.imagekit.io/stayfari/homepage/hiw/Stayfari_Testimonials_Trailer_Cover_Image_MNPrMQRn4.jpg?updatedAt=1688699157105"
        alt="Here from our customers"
      />
      <div onClick={onPlayClick} css={veil} style={{ opacity: playing ? '0' : '1' }}>
        <PlayCircleOutlined
          css={playButton}
          style={{ display: !playing && isVideoLoaded ? 'block' : 'none' }}
        />
        <Spin size="large" css={spinner} style={{ display: isVideoLoaded ? 'none' : 'block' }} />
      </div>
    </VideoBox>
  )
}
