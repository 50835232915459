import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Helmet } from 'react-helmet'
import { SiteMetadataQuery } from 'generated/types/gatsby'
import { css } from '@emotion/react'
import { Header } from './Header'
import { AuthContextProvider } from './auth/AuthContext'
import './global.less'
import { ConfigContextProvider } from './config/ConfigContext'

const wrapper = css`
  // margin: 0 auto;
  // max-width: 960px;
  // padding: 0 1.0875rem 1.45rem;
`

interface LayoutProps {
  readonly children?: React.ReactNode | readonly React.ReactNode[]
  readonly hostname: string
  readonly appHostname: string
  readonly protocol: string
}

export const Layout = ({ children, hostname, appHostname, protocol, pathname }: LayoutProps) => {
  //Useless query to enable build because of gatsby-plugin-typegen
  const data = useStaticQuery<SiteMetadataQuery>(graphql`
    query SiteMetadata {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)

  return (
    <main>
      <Helmet
        htmlAttributes={{ lang: 'en-US' }}
        titleTemplate={`%s - Hire a car and driver in India, Nepal, Sri Lanka, or Mauritius | Stayfari`}
        // titleTemplate={`%s - ${data.site.siteMetadata.title}`}
        defaultTitle={'Hire a car and driver in India, Nepal, Sri Lanka, or Mauritius | Stayfari'}
        // defaultTitle={data.site.siteMetadata.title}
        meta={[
          {
            name: 'description',
            // content: data.site.siteMetadata.description
            content:
              'Hire a car and driver in India, Sri Lanka, Nepal or Mauritius. Pickups from Delhi, Mumbai, Colombo, Kathmandu, Chennai, Bangalore, Hyderabad, Goa, Kerala and more.'
          },
          {
            name: 'keywords',
            content:
              'car, driver, hire, rent, taxi, India, minibus, tempo, van, innova, crysta, chauffeur, Delhi, Colombo, Kathmandu, Jaipur, Agra, Mumbai, Chennai, Bangalore, Hyderabad, Goa, Kerala, Rajasthan, Chandigarh, stayfari'
          }
        ]}
        link={[{ rel: 'icon', type: 'image/png', href: '/favicon/favicon.ico', sizes: '16x16' }]}
      >
        {/* // Google Tag Manager */}
        <script>
          {`(function (w, d, s, l, i) {
          w[l] = w[l] || [];
          w[l].push({'gtm.start': new Date().getTime(), event: 'gtm.js'});
          var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src ='https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
          })(window, document, 'script', 'dataLayer', 'GTM-573SVJX');
          `}
        </script>
        {/* // End Google Tag Manager */}

        {/* <script defer src="http://localhost:9001/instantbook.js"></script> */}

        {/* // Google Analytics */}
        <script async src="//www.google-analytics.com/analytics.js"></script>
        <script>
          {`
          window.ga = window.ga || function () {
            (ga.q = ga.q || []).push(arguments)
          };
          ga.l = +new Date;
          ga('create', 'UA-62595112-1', 'auto');
          ga('set', 'forceSSL', true);        // Send all data using SSL, even from insecure (HTTP) pages.
          `}
        </script>
        {/* // End Google Analytics */}

        {/* // Global site tag (gtag.js) - AdWords (AW), Google Analytics (UA), Google Optimize */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-953514674"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag() {dataLayer.push(arguments); }
          gtag('js', new Date());

          gtag('config', 'AW-953514674');
          gtag('config', 'UA-62595112-1', {'optimize_id': 'GTM-WSS952F' });
          `}
        </script>

        {/* // Bing Ads: ID26031419 */}
        <script>{`(function (w, d, t, r, u) { var f, n, i; w[u] = w[u] || [], f = function () { var o = {ti: "26031419" }; o.q = w[u], w[u] = new UET(o), w[u].push("pageLoad") }, n = d.createElement(t), n.src = r, n.async = 1, n.onload = n.onreadystatechange = function () { var s = this.readyState; s && s !== "loaded" && s !== "complete" || (f(), n.onload = n.onreadystatechange = null) }, i = d.getElementsByTagName(t)[0], i.parentNode.insertBefore(n, i) })(window, document, "script", "//bat.bing.com/bat.js", "uetq");`}</script>

        {/* // Facebook pixel */}
        <script>
          {`!function (f, b, e, v, n, t, s) {
              if (f.fbq)return;
              n = f.fbq = function () {
                n.callMethod ?
                  n.callMethod.apply(n, arguments) : n.queue.push(arguments)
              };
              if (!f._fbq)f._fbq = n;
              n.push = n;
              n.loaded = !0;
              n.version = '2.0';
              n.queue = [];
              t = b.createElement(e);
              t.async = true;
              t.src = v;
              s = b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t, s)
            }(window,
              document, 'script', '//connect.facebook.net/en_US/fbevents.js');

            fbq('init', '1602953123299946');
            fbq('track', 'PageView');`}
        </script>
      </Helmet>
      <ConfigContextProvider hostname={hostname} appHostname={appHostname} protocol={protocol}>
        <AuthContextProvider>
          {/* <Header title={data.site.siteMetadata.title} /> */}
          <Header
            title={'Hire a car and driver in India, Nepal, Sri Lanka, or Mauritius | Stayfari'}
          />
        </AuthContextProvider>
        <div css={wrapper}>{children}</div>
      </ConfigContextProvider>
    </main>
  )
}
