import React, { memo } from 'react'
// import clsx from 'clsx';
import { Typography, Row, Col, Space } from 'antd'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { CarOutlined } from '@ant-design/icons'
const { Title, Paragraph, Text } = Typography
import '../homepage.less'
// Set background for slat
const container = css`
  padding: 30px;

  @media (max-width: 768px) {
    padding: 10px;
  }
`

const title = css`
  text-align: center;
`
const col = css`
  padding: 7px;
  margin-top: auto;
  margin-bottom: auto;
`
const row1col1 = css`
  background-color: #fff;
`
const row1col2 = css`
  background-color: rgb(225, 233, 244);
`
const row1col3 = css`
  background-color: rgb(253, 250, 244);
`
const row2col1 = css`
  background-color: rgb(253, 250, 244);
  @media (max-width: 768px) {
    display: none;
  }
`
const row2col2 = css`
  background-color: rgb(255, 255, 255);
  @media (max-width: 768px) {
    display: none;
  }
`
const Container = styled('div')`
  max-width: 1280px;
  margin: auto;
`
const quote = css`
  font-size: 16px !important;
`
const sig = css`
  font-size: 16px !important;
  font-style: italic;
`
const cap = css`
  max-width: 980px;
`

const ReviewCard = (props: any) => {
  const { author, dest, children, className } = props
  return (
    <div className={`tt ${className}`}>
      <a
        href="https://www.tripadvisor.com/ShowUserReviews-g304551-d16881114-r942854288-Stayfari-New_Delhi_National_Capital_Territory_of_Delhi.html#REVIEWS"
        target="_blank"
      >
        <Paragraph css={quote}>{children}</Paragraph>
        <div className="tt-sig">
          <Text css={sig}>{author}</Text>
          <br />
          <Space className="tt-sig-from">
            <CarOutlined />
            <Text>{dest}</Text>
          </Space>
        </div>
      </a>
    </div>
  )
}

const Reviews = () => {
  return (
    <div css={container} className="sf-gradient tt-cont">
      <Container css={cap}>
        <a
          id="tripadvisor"
          href="https://www.tripadvisor.com/ShowUserReviews-g304551-d16881114-r942854288-Stayfari-New_Delhi_National_Capital_Territory_of_Delhi.html#REVIEWS"
        >
          <Title level={2} css={title}>
            Skim our reviews
          </Title>
        </a>
        <Row justify="center" align="top">
          <Col css={col} md={8} sm={12} xs={24}>
            <ReviewCard css={row1col1} author="Mark P" dest="to North India">
              We used Stayfari for our three week tour of India. The staff were easy to desk with in
              organising our trip. We had a fantastic trip and a big thanks to Stayfari.
            </ReviewCard>
          </Col>
          <Col css={col} md={8} sm={12} xs={24}>
            <ReviewCard css={row1col2} author="Mel D" dest="to Delhi &amp; Agra">
              I used Stayfari to book airport and intercity transfers 4 times during my 2 week trip
              to India. The cars were well-maintained and comfortable and the drivers punctual and
              competent. Overall a very pleasant experience.
            </ReviewCard>
          </Col>
          <Col css={col} md={8} sm={24} xs={24}>
            <ReviewCard css={row1col3} author="Catherine F" dest="to Delhi &amp; Gurugram">
              Contact via their direct message and all the way the response, quotation, payment, and
              preparing messaging group are swift and prompt. Will definitely recommend if anyone is
              planning to go to India with a reliable service provider.
            </ReviewCard>
          </Col>
          <Col css={col} md={12} sm={12} xs={24}>
            <ReviewCard css={row2col1} author="Hariharan" dest="to Delhi, Mumbai &amp; Bangalore">
              I had requested at very short notice but Stayfari ensured the car was booked to my
              specifications and on time. The driver was knowledgeable of the routes and ensured my
              travel was without any hassle or delay. The car was spotlessly clean and smooth.
              Thanks a ton Stayfari.
            </ReviewCard>
          </Col>
          <Col css={col} md={12} sm={24}>
            <ReviewCard css={row2col2} author="Sundar K" dest="to Kochi &amp; Guruvayur">
              One of the best things that happened during my trip was hiring a chauffeur-driven
              vehicle. There were no hidden costs attached and the driver was very well-behaved and
              made sure was available on demand during the entire duration of my vacation. Overall
              an amazing experience.
            </ReviewCard>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
export default memo(Reviews)
