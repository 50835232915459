import React from 'react'
import useDestinations from './useDestinations'
import { DestinationsContext } from './Destinations'

const DestinationsProvider = ({ children }: { children: any }) => {
  const destinationsState = useDestinations()

  const { Provider } = DestinationsContext

  return <Provider value={destinationsState}>{children}</Provider>
}

export default DestinationsProvider
