import React, { useEffect } from 'react'
import { Layout } from '../components/Layout'
import { withCookies } from 'react-cookie'
import DestinationsProvider from '../components/homepage/destinations/DestinationsProvider'
import Hero from '../components/homepage/Hero'
import Benefits from '../components/homepage/Benefits'
import { ViewContext } from '../components/view/ViewContext'
import { useInView } from 'react-intersection-observer'
import Advisory from '../components/homepage/Advisory'
import MultiCarousel from '../components/homepage/MultiCarousel'
import Reviews from '../components/homepage/reviews/Reviews'
import Faq from '../components/homepage/Faq'
import { Footer } from '../components/Footer'
import StickyFooter from '../components/homepage/StickyFooter'

const BelowFold = ({ setAdvisoryInView, vehicles, articles, pathname }) => {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0.1
  })

  useEffect(() => {
    setAdvisoryInView(inView)
  })

  return (
    <div ref={ref}>
      <Advisory />
      <MultiCarousel
        title="Learn about available vehicles"
        titleUrl="https://stayfari.com/blog/chauffeured-vehicles-available-in-india-sri-lanka-nepal/"
        slides={vehicles}
        imgRoot="vehicles"
      />
      <Reviews />
      <MultiCarousel
        title="Read helpful articles"
        backgroundClass="sf-reverse-gradient"
        slides={articles}
        imgRoot="articles"
      />
      <Faq />
      <Footer />
      <StickyFooter inView={inView} pathname={pathname} />
    </div>
  )
}

const BelowFoldView = ({ vehicles, articles, pathname }) => {
  return (
    <ViewContext.Consumer>
      {({ setAdvisoryInView }) => (
        <BelowFold
          setAdvisoryInView={setAdvisoryInView}
          vehicles={vehicles}
          articles={articles}
          pathname={pathname}
        />
      )}
    </ViewContext.Consumer>
  )
}

const countryMap = { LK: 'Sri Lanka', IN: 'India', MU: 'Mauritius', NP: 'Nepal' }
const countryCodeMap = { india: true, srilanka: true, mauritius: true, nepal: true }

const HomePageTemplate = ({
  pageContext: { subtitle, city, country, heroImgUrl, vehicles, articles, pathname, main, code }
}: any) => {
  return (
    <DestinationsProvider>
      {main || countryCodeMap[code] ? (
        <Hero title="Hire a car and driver" main={main} subtitle={subtitle} img={heroImgUrl} />
      ) : (
        <Hero
          title="Hire a car and driver"
          main={main}
          subtitle={`in ${city} and across ${countryMap[country]}`}
          img={heroImgUrl}
        />
      )}
      <Benefits />
      <BelowFoldView vehicles={vehicles} articles={articles} pathname={pathname} />
    </DestinationsProvider>
  )
}

const CookiedHomePage = withCookies(HomePageTemplate)

// Pass props through to cookied homepage
const LayoutHomePage = props => {
  return (
    <Layout
      hostname={props.pageContext.hostname}
      protocol={props.pageContext.protocol}
      appHostname={props.pageContext.appHostname}
      pathname={props.pageContext.pathname}
    >
      <CookiedHomePage {...props} />
    </Layout>
  )
}

export default LayoutHomePage
