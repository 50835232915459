import React from 'react'
import { Typography, Row, Col } from 'antd'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import Video from '../media/Video'
const { Title } = Typography

// Set background for slat
const container = css`
  width: 100%;
  padding: 50px;
  overflow: hidden;

  @media (max-width: 1024px) {
    padding: 30px;
  }

  @media (max-width: 768px) {
    padding: 20px;
  }
`
const title = css`
  text-align: center;
`

const row = css`
  // max-width: 980px;
  text-align: center; //applies to title and paragraphs
`

const col = css`
  padding: 10px;
`

const Container = styled('div')`
  max-width: 640px;
  margin: auto;
`

const Advisory = () => {
  return (
    <div id="testimonials" css={container} className="sf-gradient">
      <Container>
        <Title level={2} css={title}>
          Hear from our customers
        </Title>
        <Row justify="center" align="top" css={row}>
          <Col xs={24} css={col}>
            <Video
              css={css`
                width: 100%;
              `}
            />
          </Col>
        </Row>
      </Container>
    </div>
  )
}
export default Advisory
