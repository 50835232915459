import React from 'react'

type ConfigState = {
  readonly protocol: string
  readonly hostname: string
  readonly appHostname: string
  readonly baseAppUrl: string
}

export const ConfigContext = React.createContext<Partial<ConfigState>>({})

export const ConfigContextProvider = ({
  hostname,
  appHostname,
  protocol,
  children
}: {
  hostname: string
  appHostname: string
  protocol: string
  children: any
}) => {
  // Determine auth state by stored token
  const configState: Partial<ConfigState> = {
    hostname,
    appHostname,
    protocol,
    baseAppUrl: `${protocol}://${appHostname}`
  }

  return <ConfigContext.Provider value={{ ...configState }}>{children}</ConfigContext.Provider>
}
export const ConfigContextConsumer = ConfigContext.Consumer
